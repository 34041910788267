

:root {
  --purple: #6601ea;
}

iframe{
  overflow: hidden;
  border:none;
}
.App {
  font-family: 'Pixelify Sans', sans-serif;
  background-color: black;
}
.more-lands{
  width: 100%;
  text-align: center;
}
button{
  background-color: var(--purple);
  color: white;
  border-radius: 5px;
  font-size: 20px;
  padding: 10px;
  font-family: 'Pixelify Sans', sans-serif;
}

.more-lands button{
 margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  border: none;
 


}
body{
  height:100dvh;
  color:antiquewhite;
  background-color: black;
}
header{
  font-family: 'Pixelify Sans', sans-serif;
  font-size: 40px;
  padding:15px;
  background-color: var(--purple);
}

.container{
  display: grid;
  grid-template-columns: repeat(auto-fit, 20%); /* Creates three columns of equal width */
  gap: 30px; /* Space between grid items */
  padding:15px;
  justify-content: center;
  
}
.container div {
  aspect-ratio: 1/1;

}
/* .container div div { */
 /* background-color: red; */
 /* width: 100%;
 height: 100%; */
 /* margin:10px; */

/* } */
/* @media screen and (max-width: 600px) {
  .container{
    flex-direction: row;

  }
  .container div {
    width: 100%;
    background-color: red; 
  }
  
} 
*/

/* @media screen and (min-width: 1000px) {
  .container{
    max-width: 1000px;
    margin: auto;
  }
  
  
} */
.land{
  position: relative;
  border: 2px solid var(--purple);
  border-radius: 10px;
  overflow: hidden;
  padding:5px;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.land div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0);
}
.land  iframe {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}